.SocialMediaShare > * {
  padding: 1rem;
}

.share_icons {
  margin: 0.4rem;
  border-radius: 4px;
  text-align: center;
  display: inline-flex;
  padding: 0.25rem 0.6rem 0.25rem 0rem !important;
  color: white !important;
  align-items: center;
}
.fbBtn {
  background-color: #3b5998 !important;
}
.twitterBtn {
  background-color: #00aced !important;
}
.whatsappBtn {
  background-color: #25d366 !important;
}
.linkedinBtn {
  background-color: #007fb1 !important;
}
