.HorizontalCard {
  padding: 1.5rem 1rem;
  text-align: center;
  border-radius: 5px; /* 5px rounded corners */
}
.red {
  background-color: rgba(255, 0, 0, 0.1);
  color: rgba(255, 0, 0, 0.7);
  box-shadow: 0px 0px 2px 2px rgb(255 0 0 / 20%);
}
.green {
  background-color: rgba(0, 146, 0, 0.1);
  color: rgba(0, 146, 0, 0.7);
  box-shadow: 0px 0px 2px 2px rgb(0 146 0 / 20%);
}
.blue {
  background-color: rgba(0, 120, 255, 0.1);
  color: rgba(0, 120, 255, 0.7);
  box-shadow: 0px 0px 2px 2px rgb(0 120 255 / 20%);
}
.pink {
  background-color: rgba(255, 0, 160, 0.1);
  color: rgba(255, 0, 160, 0.7);
  box-shadow: 0px 0px 2px 2px rgb(255 0 160 / 20%);
}
.orange {
  background-color: rgba(255, 80, 0, 0.1);
  color: rgba(255, 80, 0, 0.7);
  box-shadow: 0px 0px 2px 2px rgb(255 80 0 / 20%);
}
