$red: #ff3252;
$size: 1.3rem;
@keyframes heartbeat {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  20% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  40% {
    transform: translate(-50%, -50%) scale(1);
  }
  60% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.like-button {
  display: inline-block;
  position: relative;
  left: 10px;
  font-size: $size;
  cursor: pointer;
  &::before {
    font-size: $size;
    content: "🤍";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: heartbeat 2s;
    animation-delay: 1s;
  }

  &::after {
    font-size: $size;
    color: $red;
    content: "❤️";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s;
  }
  &.liked::after {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
