.username {
  padding: 1rem 2rem;
}

.logout_btn {
  text-align: center;
  padding-bottom: 1rem;
}

.avatar {
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0;
}
