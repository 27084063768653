.SavedQueries {
  border: 1px gray;
  box-shadow: 1px;
  width: 100%;
}
.queryTitle {
  flex-grow: 1;
  transition: all;
  font-size: large;
  transition-duration: 0.5s;
  margin-left: 2rem;

  overflow: hidden;
  text-overflow: ellipsis;
}
.queryTitle:hover {
  color: lightblue;
}
.savedQuery {
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 146, 0, 0.7);
  padding: 0.5rem 0.2rem;
  margin-bottom: 0.5rem;
}

.loader {
  display: flex;
  justify-content: center;
}
