@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

html,
body {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-form-item-label > label {
  font-size: 18px;
  width: 100%;
}

.btn-animation {
  /* transition-delay: 0.5s; */
  transition: all 0.2s ease;
}

.btn-animation:hover {
  transform: scale(110%);
}

.ant-btn-lg {
  height: 50px;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  overflow-x: hidden;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #efefef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px;
}

.ant-message {
  bottom: 20px;
  top: initial;
}

.mt-16 {
  margin-top: 16px;
}

.cursor-pointer {
  cursor: pointer;
}
.photoloader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid blue;
  width: 26px;
  height: 26px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
