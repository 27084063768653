.input {
  border: 1px solid gray;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.focusedInput {
  border: 1px solid skyblue !important;
  box-shadow: 2px !important;
}
.inputWrapper {
  flex-grow: 1;
}
.button {
  width: 100%;
  transition: all 0.2s ease;
}

.button:hover {
  transform: scale(110%);
}

.container {
  display: flex;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: black;
}

.container:focus {
  border: 1px solid skyblue !important;
  box-shadow: 2px !important;
}

.container input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  /* padding: 14px; */
  padding-left: 14px;
}

.ant-input-focused {
  box-shadow: none !important;
}

.tagInput {
  box-shadow: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.closeicon {
  padding-left: 0.5rem;
}

.tag {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 2px 0;
  margin-right: 10px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #fd87ba;
  white-space: nowrap;
  color: white;
}

.orTag {
  border: 1px solid rgba(78, 140, 246, 0.616);
  background-color: rgba(78, 140, 246, 0.616);
}

.andTag {
  border: 1px solid darkgray;
  background-color: darkgray;
}

.tag button {
  display: flex;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}

.query_submit {
  margin-top: 1rem;
}

.feedback_containter {
  margin-top: 1rem;
}

.exact_checkbox {
  margin: 0.2rem 0 0rem 0rem;
  font-weight: 500;
  font-size: 16px;
}

.ant-form label {
  font-size: 15px;
}
